import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material';
import SLocationService from '../../services/location/location.service';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import './Location.scss';
import ListLocations from './ListLocations';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';
import { ILocationDetails, LocationTypes } from 'types/location';

const Location: React.FunctionComponent = () => {
  const [selectedProject, setSelectedProject] = useState<number>();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [locationList, setLocationList] = useState<ILocationDetails[]>([]);
  const [locationNamesList, setLocationNamesList] = useState([]);
  const [selectedValue, setSelectedValue] = useState<number>(10);

  const handleAllLocationsName = (h2DigitalType: LocationTypes) => {
    SLocationService.getAllLocationsNames([h2DigitalType])
      .then((response) => {
        setLocationNamesList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleAllLocationsName(LocationTypes.Provider);
  }, []);

  const fetchAllLocations = async (selectedProjectId: number) => {
    await SLocationService.findLocationNames(selectedProjectId)
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: { name: string; id: number; projectId: number }
  ) => {
    setSelectedProject(newValue?.projectId);
    fetchAllLocations(newValue?.projectId);
    setLocationList([]);
  };

  const sortProjectsByName = (projectName: any[]) => {
    return projectName.slice().sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleDeletelocation = (id: string): void => {
    if (selectedProject)
      SLocationService.deleteLocation(id)
        .then(() => {
          fetchAllLocations(selectedProject);
          setDeleteSuccess(true);
          setStatusOpen(true);
        })
        .catch((error) => {
          alert(error.message);
          setStatusOpen(true);
        });
  };

  const handleDeleteClose = () => {
    setStatusOpen(false);
    return;
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    const valueSelected = Number(event.target.value);
    setLocationList([]);
    handleAllLocationsName(valueSelected);
    setSelectedValue(valueSelected);
  };

  return (
    <div className="location-main-container">
      <div className="location-details-title"> Location</div>
      <div className="project-select-main-conatiner">
        <div className="projecttype-select-label">
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="project-location-select-label">Type</InputLabel>
            <Select value={selectedValue} label="Type" onChange={handleChange}>
              <MenuItem value={LocationTypes.Provider}>Provider</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="project-select-location">
          <FormControl required sx={{ mb: 5, mt: 5, minWidth: 300 }}>
            {locationNamesList.length > 0 && (
              <ProjectAutocomplete
                label="Location*"
                projectsList={sortProjectsByName(locationNamesList)}
                onSelectedProjectChange={onSelectedProjectChange}
              />
            )}
          </FormControl>
        </div>
      </div>
      {selectedProject && (
        <ListLocations
          selectedProject={selectedProject}
          fetchAllLocations={fetchAllLocations}
          locationsList={locationList}
          handleDeletelocation={handleDeletelocation}
        />
      )}
      <Snackbar
        open={statusOpen}
        autoHideDuration={6000}
        onClose={handleDeleteClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleDeleteClose}
          severity={deleteSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {deleteSuccess ? 'Deletion Successful' : 'Deletion  Failed'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Location;
