export interface IUserDetails {
  id: string;
  description: string;
  emailAddress: string;
  isAdmin: boolean;
  isLocked: boolean;
  isSuperUser: boolean;
  aadObjectId?: string;
  secondaryEmail?: string;
  projectId?: number | null;
  userType?: number;
}

export enum DomainNames {
  'h2Digital' = '@h2-digital.com',
}
