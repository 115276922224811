import { ProjectHeadCell } from 'types/project';

export const projectHeadCells: readonly ProjectHeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    sort: true,
    label: 'Project Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sort: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sort: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    sort: true,
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Adress',
    sort: false,
  },
];

export const initialProjectDetails = {
  name: '',
  description: '',
  firstName: '',
  lastName: '',
  email: '',
  secondaryEmail: '',
  address: '',
};
