interface IUserTypeMap {
  label: string;
  value: number;
}

export enum UserTypes {
  H2DigitalUser = 5,
  Provider = 10,
  ProjectUser = 20,
}

export const mapUserType: IUserTypeMap[] = [
  { value: UserTypes.H2DigitalUser, label: 'H2 Digital User' },
  { value: UserTypes.Provider, label: 'Provider User' },
  { value: UserTypes.ProjectUser, label: 'Project User' },
];

export const mapDomain = [
  { value: '@h2-digital.com', label: '@h2-digital.com' },
];

export const InitialUserDetails = {
  id: '',
  description: '',
  emailAddress: '',
  isAdmin: false,
  isLocked: false,
  isSuperUser: false,
  aadObjectId: '',
  secondaryEmail: '',
  projectId: null,
  userType: UserTypes.ProjectUser,
};
