import { IReportDetails } from 'types/reports';
import HttpClient from '../httpClient/httpClient';
import { commonConstants } from 'constants/common';

class ReportsService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async getReportsByProjectId(projectId: string) {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/power-bi/all/${projectId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async createNewPowerBIReport(
    reportDetails: IReportDetails & { projectId: string }
  ) {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/power-bi/create-report`,
      reportDetails
    );
    return response;
  }

  async updatePowerBIReport(
    reportDetails: IReportDetails & { projectId: string },
    reportId: string
  ) {
    const response = await this.httpClient.patch(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/power-bi/update-report/${reportId}`,
      reportDetails
    );
    return response;
  }

  async deletePowerBIReport(reportId: string) {
    const response = await this.httpClient.delete(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/power-bi/delete-report/${reportId}`
    );
    return response;
  }
}
const SReportsService = new ReportsService();
export default SReportsService;
