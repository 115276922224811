import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import './TSOSetup.scss';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import ImageIcon from '@mui/icons-material/Image';
import {
  INewUserDetails,
  IUserDetailsError,
} from '@pages/Users/components/NewUserWithTeams';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';

interface IUserSetupProps {
  newUserDetails: INewUserDetails;
  setNewUserDetails: Dispatch<SetStateAction<INewUserDetails>>;
  userLogoFile: Blob | undefined;
  setUserLogoFile: Dispatch<SetStateAction<Blob | undefined>>;
  userDetailsError: IUserDetailsError;
  setUserDetailsError: Dispatch<SetStateAction<IUserDetailsError>>;
  userNameExists: boolean | undefined;
  createNewUser: () => void;
}

export const UserFragment: FunctionComponent<IUserSetupProps> = (props) => {
  const {
    newUserDetails,
    setNewUserDetails,
    userLogoFile,
    setUserLogoFile,
    userDetailsError,
    setUserDetailsError,
    userNameExists,
    createNewUser,
  } = props;

  const userLogoInput = useRef<HTMLInputElement>(null);
  const [userLogoUrl, setUserLogoUrl] = useState<string>('');
  const { showSnackbar } = useSnackbar();

  const handleUserLogoClick = () => {
    userLogoInput?.current?.click();
  };
  const handleUserLogoChange = (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    setUserDetailsError({ ...userDetailsError, userAvatar: '' });
    setUserLogoFile(event.target.files[0]);
  };
  const handleLogoUrlChange = (event: { target: { value: string } }) => {
    setUserLogoUrl(event.target.value);
  };
  const handleUploadLogo = async () => {
    if (userLogoUrl.trim() !== '') {
      fetch(userLogoUrl)
        .then((response) => response.blob())
        .then((file) => {
          if (file.type.startsWith('image/')) {
            setUserDetailsError({
              ...userDetailsError,
              userAvatar: '',
            });
            setUserLogoFile(file);
            setUserLogoUrl('');
          } else {
            showSnackbar('Fetch Logo Failed!', 'error');
            setUserLogoUrl('');
          }
        })
        .catch(() => {
          showSnackbar('Fetch Logo Failed!', 'error');
          setUserLogoUrl('');
        });
    }
  };

  const handleFormChange = (event: any) => {
    const name = event.target.name;
    const value =
      event.target.name == 'isAdmin'
        ? event.target.checked
        : event.target.value;
    setUserDetailsError({
      ...userDetailsError,
      [name]: '',
    });
    setNewUserDetails({ ...newUserDetails, [name]: value });
  };

  return (
    <Grid container xs={12} className="project-container">
      <Grid xs={4} className="project-image-container">
        <Grid xs={12} className="project-image">
          <div className="project-image-title">User Image*</div>
          {userLogoFile ? (
            <div className="project-image-wrap">
              <img
                className="project-img"
                src={URL.createObjectURL(userLogoFile)}
                onClick={handleUserLogoClick}
              />
            </div>
          ) : (
            <div className="project-image-wrap">
              <ImageIcon
                className="project-image-icon"
                onClick={handleUserLogoClick}
              />
            </div>
          )}
          <div className="error-class">
            {userDetailsError?.userAvatar ? 'User Logo cannot be empty' : ''}
          </div>
          <input
            type="file"
            ref={userLogoInput}
            onChange={handleUserLogoChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <div className="image-url-wrapper">
            <Tooltip title="Enter the image URL to fetch the image">
              <TextField
                size="small"
                id="image-text-input"
                type="text"
                onChange={handleLogoUrlChange}
                onBlur={handleUploadLogo}
                value={userLogoUrl}
                placeholder="Enter image URL"
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid xs={8} container spacing={1} className="project-details-container">
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            value={newUserDetails?.firstName || ''}
            error={userDetailsError.firstName ? true : false}
            helperText={userDetailsError.firstName || ''}
            name="firstName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            value={newUserDetails?.lastName || ''}
            error={userDetailsError.lastName ? true : false}
            helperText={userDetailsError.lastName || ''}
            name="lastName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="secondaryEmail"
            label="Secondary Email"
            type="text"
            variant="outlined"
            value={newUserDetails?.secondaryEmail || ''}
            error={userDetailsError.secondaryEmail ? true : false}
            helperText={userDetailsError.secondaryEmail || ''}
            name="secondaryEmail"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="userName"
            label="Username"
            type="text"
            variant="outlined"
            value={newUserDetails?.userName || ''}
            error={
              userDetailsError.userName ||
              (userNameExists && Boolean(newUserDetails.userName))
                ? true
                : false
            }
            helperText={
              userDetailsError.userName ||
              (userNameExists && Boolean(newUserDetails.userName)
                ? `${newUserDetails.userName} already exists`
                : '')
            }
            name="userName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            disabled
            className="project-detail-input"
            required
            margin="dense"
            id="domain"
            label="Domain"
            type="text"
            variant="outlined"
            value={newUserDetails?.domain || ''}
            name="domain"
          />
          {/* The below code is commented for future use... */}
          {/* <InputLabel id="userType-label">Domain*</InputLabel>
            <Select
              labelId="userType-label"
              id="domain"
              label="Domain"
              name="domain"
              value={newUserDetails?.domain || ''}
              onChange={handleFormChange}
            >
              {Object.entries(DomainNames).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={value}>
                    <div>{value}</div>
                  </MenuItem>
                );
              })}
            </Select> */}
        </Grid>
        <Grid item xs={2} className="project-detail-input-container">
          <FormControlLabel
            disabled
            control={
              <Checkbox
                name="isAdmin"
                id="isAdmin"
                value={newUserDetails?.isAdmin || false}
                checked={newUserDetails.isAdmin}
                onChange={handleFormChange}
              />
            }
            label="IsAdmin"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={createNewUser}>
            Create New User
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
