import { FunctionComponent, useEffect, useRef, useState } from 'react';
import './tag.component.scss';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from '@mui/material';
import { ITag } from 'interfaces/tag.interface';

interface TagProps {
  tag: ITag;
  deleteAction?: (id: number) => void;
  deleteEnabled?: boolean;
}

export const TagComponent: FunctionComponent<TagProps> = ({
  tag,
  deleteAction,
  deleteEnabled,
}) => {
  const spanRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (spanRef.current) {
        const { clientWidth, scrollWidth } = spanRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    checkOverflow(); // Check initially

    // Optionally, add a resize event listener to check on window resize
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <div
      className={`tag ${
        deleteEnabled ? 'delete-enabled' : ''
      } ${tag.accessType.toLowerCase()}`}
    >
      <span className="key">{`${tag.key}: `}</span>
      {isOverflowing ? (
        <Tooltip title={tag.value}>
          <span className="value overflow" ref={spanRef}>
            {tag.value}
          </span>
        </Tooltip>
      ) : (
        <span className="value" ref={spanRef}>
          {tag.value}
        </span>
      )}
      {deleteEnabled && deleteAction && (
        <ClearIcon
          className="delete-button"
          onClick={() => deleteAction(tag.id)}
        />
      )}
    </div>
  );
};
