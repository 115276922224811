import { PowerBIReportTypes, ReportStatus } from 'types/reports';

export const defaultReportError = {
  reportId: '',
  datasetId: '',
  workspaceId: '',
};

export const defaultReportDetails = {
  powerBIReportTypes: PowerBIReportTypes.SUMMARY,
  reportStatus: ReportStatus.ACTIVE,
  reportId: '',
  datasetId: '',
  workspaceId: '',
  reportName: '',
  roleName: '',
};
