import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import './Users.scss';
import SProjectService from '../../services/project/project.service';
import SUserService from '../../services/user/user.service';
import { NewUserDialogWithTeams } from './components/NewUserWithTeams';
import { IUserDetails } from 'types/users';
import ProjectAutocomplete from '../../components/ProjectAutocomplete/ProjectAutocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import FactoryIcon from '@mui/icons-material/Factory';
import { InitialUserDetails } from '../../constants/user';
import { IProjectMini } from 'types/project';
import { debounce } from 'lodash';
import { AxiosError } from 'axios';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { emailExpression } from 'constants/common';
import { ListUsers } from './components/ListUsers';
import _ from 'lodash';
import { EditUserDetails } from './components/EditUserDetails';
import { IProjectInfo } from 'interfaces/project.interface';

interface AADUserType {
  id: string;
  userPrincipalName: string;
  otherMails: string[];
}

const Users: React.FunctionComponent = () => {
  const [selectedProject, setSelectedProject] = useState<IProjectMini>();
  const [projectsList, setProjectsList] = useState<IProjectInfo[]>([]);
  const [userProject, setUserProject] = useState<IProjectMini>();
  const [AADUsers, setAADUsers] = useState<AADUserType[]>([]);
  const [usersList, setUsersList] = useState<IUserDetails[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [newUserDialogue, setNewUserDialogue] = useState(false);
  const [editUserDialogue, setEditUserDialogue] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [selectedUser, setSelectedUser] =
    useState<IUserDetails>(InitialUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyProject, setEmptyProject] = useState(false);
  const [selectedAADUser, setSelectedAADUser] = useState<AADUserType>();
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const { showSnackbar } = useSnackbar();

  const fetchUsersWithProject = (projectId: number) => {
    SUserService.getUsersByProjectId(projectId)
      .then((response) => {
        setUsersList(response);
        setUsersLoading(false);
      })
      .catch((error) => {
        setUsersLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    SProjectService.findNames()
      .then((response) => {
        setProjectsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleProjectSelect = (
    e: SyntheticEvent<Element, Event>,
    project: IProjectMini
  ) => {
    setUsersLoading(true);
    setUserProject(project);
    setSelectedProject(project);
    fetchUsersWithProject(project?.id);
  };

  const handleCloseEdit = () => {
    setEditUserDialogue(false);
  };

  const handleUpdateUserDetails = () => {
    if (selectedUser.secondaryEmail) {
      const validEmail = emailExpression.test(selectedUser.secondaryEmail);
      if (validEmail) {
        const userData = _.omit(selectedUser, 'id');
        SUserService.updateUserDetails(userData, selectedUser.id).then(() => {
          handleCloseEdit();
          if (selectedProject?.id) fetchUsersWithProject(selectedProject.id);
          showSnackbar('User details updated successfully!', 'success');
        });
      } else {
        setEmailError('Please enter a valid email!');
      }
    } else {
      setEmailError('Secondary Email cannot be empty!!!');
    }
  };

  const handleEditUser = (user: IUserDetails) => {
    setSelectedUser(user);
    const projectAssociatedWithUser = projectsList.find(
      (project) => project.id === user.projectId
    );
    setUserProject(projectAssociatedWithUser);
    setEditUserDialogue(true);
  };

  const resetPage = () => {
    setEmptyProject(false);
    setSelectedUser(InitialUserDetails);
    setUserProject(undefined);
    setSelectedProject(undefined);
    setSelectedAADUser(undefined);
    setUsersList([]);
  };

  const handleTabChange = (e: React.SyntheticEvent, tab: number) => {
    setTabValue(tab);
    resetPage();
  };

  const getUserDetailsByAADId = (AADUserId: string) => {
    SUserService.getUserByAadId(AADUserId).then((response) => {
      if (response.data) {
        setEmptyProject(false);
        handleEditUser(response.data);
      } else {
        setEmptyProject(true);
      }
    });
  };

  const handleAADUserSelect = (
    e: SyntheticEvent<Element, Event>,
    AADUser: AADUserType | null
  ) => {
    if (AADUser) {
      setSelectedAADUser(AADUser);
      getUserDetailsByAADId(AADUser.id);
    }
  };

  const handleCreateUserInDB = async () => {
    if (selectedAADUser) {
      const userData = {
        emailAddress: selectedAADUser.userPrincipalName, //get email from AAD,
        isAdmin: false,
        isLocked: false,
        isSuperUser: false,
        aadObjectId: selectedAADUser.id,
      };
      await SUserService.addUserDetails(userData);
      getUserDetailsByAADId(selectedAADUser.id);
    }
  };

  //  Fetch aad users based on user input.
  const getAADUsers = useCallback(
    debounce(
      (
        query: string,
        signal: AbortSignal,
        callback: (options: any) => void
      ) => {
        setAADUsers([]);
        SUserService.getAADUsers(query, signal)
          .then(callback)
          .catch((err: Error | AxiosError) => {
            if (err.name !== 'CanceledError') {
              alert(err.message);
            }
          });
      },
      250
    ), // optimal debounce Time for a good UX
    []
  );

  useEffect(() => {
    if (tabValue === 1) {
      setIsLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;
      getAADUsers(searchQuery, signal, (users: { data: AADUserType[] }) => {
        setAADUsers(users.data);
        setIsLoading(false);
      });

      return () => {
        controller.abort();
      };
    }
  }, [searchQuery, getAADUsers, tabValue]);

  return (
    <div className="user-main-container">
      <div className="user-overview-header-container">
        <div className="user-details-title"> Users</div>
      </div>

      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab icon={<FactoryIcon />} label="BY PROJECTS" />
        <Tab icon={<PersonPinIcon />} label="FROM AAD" />
      </Tabs>
      {tabValue === 0 ? (
        <>
          <div className="project-select-user">
            <FormControl required sx={{ mb: 5, minWidth: 360 }}>
              {projectsList?.length ? (
                <ProjectAutocomplete
                  projectsList={projectsList}
                  onSelectedProjectChange={handleProjectSelect}
                  value={selectedProject}
                />
              ) : null}
            </FormControl>
          </div>
          {selectedProject && (
            <>
              <ListUsers
                loading={usersLoading}
                usersList={usersList}
                onUserEdit={handleEditUser}
                refreshUsersList={() => {
                  fetchUsersWithProject(selectedProject.id);
                }}
              />
              <div className="new-user-button-container-space">
                <div className="new-user-button-container">
                  <Button
                    sx={{
                      backgroundColor: '#234C8A',
                      color: '#ffff',
                      fontFamily: 'Inria Sans',
                      '&:hover': {
                        backgroundColor: '#ffff',
                        color: '#234c8a',
                      },
                    }}
                    variant="outlined"
                    disabled={usersLoading}
                    onClick={() => setNewUserDialogue(true)}
                  >
                    Add User
                  </Button>
                </div>
              </div>
              <NewUserDialogWithTeams
                open={newUserDialogue}
                projectId={selectedProject.id}
                projectName={selectedProject.name}
                handleClose={() => setNewUserDialogue(false)}
                refreshUsersList={() => {
                  fetchUsersWithProject(selectedProject.id);
                }}
              ></NewUserDialogWithTeams>
            </>
          )}
        </>
      ) : (
        <>
          <div className="aad-users-container">
            <Autocomplete
              id="user-autocomplete"
              options={AADUsers}
              loading={isLoading}
              getOptionLabel={(user) => user.userPrincipalName}
              onChange={handleAADUserSelect}
              value={selectedAADUser}
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) => {
                setSearchQuery(newInputValue);
              }}
              selectOnFocus
              isOptionEqualToValue={(option, value) =>
                option.userPrincipalName === value.userPrincipalName
              }
              renderInput={(params) => (
                <TextField {...params} label="Select User" />
              )}
            />
          </div>
          {emptyProject && (
            <>
              <div className="no-project-msg">
                The selected user has no associated project
              </div>
              <div>
                <div className="add-project-btn">
                  <Button
                    sx={{
                      backgroundColor: '#234C8A',
                      color: '#ffff',
                      fontFamily: 'Inria Sans',
                      '&:hover': {
                        backgroundColor: '#ffff',
                        color: '#234c8a',
                      },
                    }}
                    variant="outlined"
                    onClick={handleCreateUserInDB}
                  >
                    Add Project To User
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <EditUserDetails
        editUserDialogue={editUserDialogue}
        handleCloseEdit={handleCloseEdit}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        emailError={emailError}
        setEmailError={setEmailError}
        projectsList={projectsList}
        userProject={userProject}
        setUserProject={setUserProject}
        handleUpdateUserDetails={handleUpdateUserDetails}
      />
    </div>
  );
};

export default Users;
