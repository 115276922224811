/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Darshan Sannamuddaiah, darshan.sannamuddaiah@h2-digital.com, January 2023
*******************************************************/

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  debounce,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import './NewUserDialog.scss';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import { mapDomain, mapUserType } from 'constants/user';
import { NewUserValidator } from 'utils/NewUserValidator';
import SUserDetailsService from 'services/userDetails/userDetails.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import SProjectService from 'services/project/project.service';
import STeamsService from 'services/teams/teams.service';
import SUserService from 'services/user/user.service';
import { addNewUserToProjectMsgTeams } from 'utils/teamsMessage';
import { matches } from 'lodash';

export interface INewUserDetails {
  firstName: string;
  lastName: string;
  userName: string;
  domain: string;
  secondaryEmail: string;
  isAdmin: boolean;
  isSuperUser: boolean;
  isLocked: boolean;
  userType: number;
}

interface InputType {
  name: string;
  label: string;
}

export interface IUserDetailsError {
  firstName?: string;
  lastName?: string;
  userName?: string;
  secondaryEmail?: string;
  userAvatar?: string;
}

interface NewUserDialogProps {
  open: boolean;
  projectId: number;
  projectName: string;
  refreshUsersList: () => void;
  handleClose: () => void;
}

export const NewUserDialog: FunctionComponent<NewUserDialogProps> = ({
  open,
  projectId,
  projectName,
  refreshUsersList,
  handleClose,
}: NewUserDialogProps) => {
  const checkBoxes: InputType[] = [
    { name: 'isAdmin', label: 'Admin' },
    { name: 'isSuperUser', label: 'Super User' },
    { name: 'isLocked', label: 'Locked' },
  ];

  const userImageInput = useRef<HTMLInputElement>(null);
  const [userAvatar, setUserAvatar] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const [userNameExists, setUserNameExists] = useState<boolean>();
  const [newUserDetails, setNewUserDetails] = useState<INewUserDetails>({
    firstName: '',
    lastName: '',
    userName: '',
    secondaryEmail: '',
    domain: mapDomain[0].value,
    isAdmin: false,
    isLocked: false,
    isSuperUser: false,
    userType: mapUserType[0].value,
  });
  const [userDetailsError, setUserDetailsError] = useState<IUserDetailsError>({
    firstName: '',
    lastName: '',
    userName: '',
    secondaryEmail: '',
    userAvatar: '',
  });

  const { showSnackbar } = useSnackbar();

  const handleCheckBoxValueChange = <Key extends keyof INewUserDetails>(
    property: Key
  ) => {
    setNewUserDetails((prevUser) => ({
      ...prevUser,
      [property]: !prevUser[property],
    }));
  };

  const checkUserNameExists = (signal: AbortSignal) => {
    const userid =
      newUserDetails.userName?.toLowerCase() + newUserDetails.domain;
    const delayedCheck = debounce(async () => {
      const exists = await SUserDetailsService.checkH2DigitalUserExists(
        userid,
        signal
      );
      setUserNameExists(exists.data);
    }, 500);
    delayedCheck();
  };

  useEffect(() => {
    setUserNameExists(undefined);
    const controller = new AbortController();
    const signal = controller.signal;
    if (newUserDetails.userName?.trim().length) checkUserNameExists(signal);
    else setUserNameExists(undefined);
    return () => {
      controller.abort();
    };
  }, [newUserDetails.userName, newUserDetails.domain]);

  const onFieldsChange = async (value: string | number, key: string) => {
    if (newUserDetails) {
      setUserDetailsError({
        ...userDetailsError,
        [key]: '',
      });
      setNewUserDetails({
        ...newUserDetails,
        [key]: value,
      });
    }
  };

  const handleClosePopup = () => {
    setNewUserDetails({
      firstName: '',
      lastName: '',
      userName: '',
      secondaryEmail: '',
      domain: mapDomain[0].value,
      isAdmin: false,
      isLocked: false,
      isSuperUser: false,
      userType: mapUserType[0].value,
    });
    setUserAvatar(undefined);
    setUserDetailsError({
      firstName: '',
      lastName: '',
      userName: '',
      secondaryEmail: '',
      userAvatar: '',
    });
    handleClose();
  };

  const handleSubmit = () => {
    if (!isLoading) {
      setUserDetailsError({
        firstName: '',
        lastName: '',
        userName: '',
        secondaryEmail: '',
        userAvatar: '',
      });
      const errors = NewUserValidator(newUserDetails, userAvatar);
      setUserDetailsError((prev) => {
        return { ...prev, ...errors };
      });
      if (matches(errors)({}) && userAvatar && !userNameExists) {
        setIsLoading(true);
        SProjectService.addNewUserToProject(projectId, newUserDetails)
          .then((res) => {
            if (res.data.user) {
              res.data = { ...res.data, projectName };
              const message = addNewUserToProjectMsgTeams(res.data);
              STeamsService.sendNotificationInTeams(
                h2DigitalTeamsForNotification.link,
                message
              );
            }
            setIsLoading(false);
            SUserService.uploadAvatar(userAvatar, res.data.user.userId).then(
              () => {
                refreshUsersList();
              }
            );
            showSnackbar('User creation success', 'success');
            handleClosePopup();
          })
          .catch(() => {
            setIsLoading(false);
            showSnackbar('User creation failed', 'error');
            handleClosePopup();
          });
      }
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setUserAvatar(undefined);
      return;
    }
    const file = event.target.files[0];
    setUserDetailsError({
      ...userDetailsError,
      userAvatar: '',
    });
    setUserAvatar(file);
  };

  const handleUserImageClick = () => {
    userImageInput?.current?.click();
  };

  return (
    <div className="new-user-dialog">
      <Dialog open={open}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the form to create a new user
          </DialogContentText>
          <div className="inputs-container">
            <div className="image-container">
              <div className="new-project-image-title">User Image *</div>
              {!userAvatar ? (
                <div className="new-project-image-wrap">
                  <ImageIcon
                    className="new-project-image-icon"
                    onClick={handleUserImageClick}
                  />
                </div>
              ) : (
                <div className="new-project-image-wrap">
                  <img
                    className="new-project-image"
                    src={URL.createObjectURL(userAvatar)}
                    onClick={handleUserImageClick}
                  />
                </div>
              )}
              <div className="error-class">
                {userDetailsError?.userAvatar
                  ? 'User Logo cannot be empty'
                  : ''}
              </div>
            </div>
            <input
              type="file"
              ref={userImageInput}
              onChange={handleImageChange}
              style={{ display: 'none' }}
              accept="image/png, image/jpeg"
            />
            <div className="textfield-container">
              <TextField
                rows={2}
                required
                label="First Name"
                className="text-input"
                autoFocus
                error={userDetailsError.firstName ? true : false}
                helperText={userDetailsError.firstName || ''}
                margin="dense"
                type="text"
                variant="standard"
                onChange={(e) => onFieldsChange(e.target.value, 'firstName')}
              />
              <TextField
                rows={2}
                required
                label="Last Name"
                className="text-input"
                autoFocus
                error={userDetailsError.lastName ? true : false}
                helperText={userDetailsError.lastName || ''}
                margin="dense"
                type="text"
                variant="standard"
                onChange={(e) => onFieldsChange(e.target.value, 'lastName')}
              />
              <TextField
                rows={2}
                required
                label="Username"
                className="text-input"
                autoFocus
                error={
                  userDetailsError.userName ||
                  (userNameExists && Boolean(newUserDetails.userName))
                    ? true
                    : false
                }
                helperText={
                  userDetailsError.userName ||
                  (userNameExists && Boolean(newUserDetails.userName)
                    ? `${newUserDetails.userName} already exists`
                    : '')
                }
                margin="dense"
                type="text"
                variant="standard"
                onChange={(e) => onFieldsChange(e.target.value, 'userName')}
              />
              <TextField
                rows={2}
                required
                label="Domain"
                className="text-input"
                autoFocus
                value={newUserDetails.domain}
                margin="dense"
                type="text"
                variant="standard"
                disabled
              />

              <TextField
                rows={2}
                required
                label="Secondary Email"
                className="text-input"
                autoFocus
                error={userDetailsError.secondaryEmail ? true : false}
                helperText={userDetailsError.secondaryEmail || ''}
                margin="dense"
                type="text"
                variant="standard"
                onChange={(e) =>
                  onFieldsChange(e.target.value, 'secondaryEmail')
                }
              />
              <div className="field-select">
                <FormControl>
                  <InputLabel id="user-type-select-label">
                    Select User Type
                  </InputLabel>
                  <Select
                    required
                    variant="standard"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select"
                    fullWidth={true}
                    value={newUserDetails.userType}
                    onChange={(e) => onFieldsChange(e.target.value, 'userType')}
                  >
                    {mapUserType.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <FormGroup className="checkbox-container">
            {checkBoxes.map((checkBox, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      value={
                        newUserDetails[checkBox.name as keyof INewUserDetails]
                      }
                      onChange={() => {
                        handleCheckBoxValueChange(
                          checkBox.name as keyof INewUserDetails
                        );
                      }}
                    />
                  }
                  label={checkBox.label}
                />
              );
            })}
          </FormGroup>
          <DialogActions>
            <Button onClick={handleClosePopup}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={'20px'}></CircularProgress>
              ) : (
                'Create'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default NewUserDialog;
