import Sidebar from '../components/Sidebar/Sidebar';
import { Routes, Route } from 'react-router-dom';
import './PageContainer.component.scss';
import Project from './Project/Project';
import Reports from './Reports/Reports';
import Location from '../pages/Location/Location';
import Users from './Users/Users';
import { useState, useEffect } from 'react';
import { HeaderComponent } from '../components/Header/Header.component';
import { ProfileContainerComponent } from '../components/ProfileContainer/ProfileContainer.component';
import { FallBack } from './Fallback/fallback.component';
import SUserDetailsService from '../services/userDetails/userDetails.service';
import TSOSetup from './TSOSetup/TSOSetup';
import DriverFunction from './DriverFunction/DriverFunction';
import Teams from './Teams/Teams';
import Tags from './Tags/Tags';
import { Map } from './EmbeddedIFrame/Map';

export const PageContainerComponent = () => {
  const [sidebarClose, setSidebarClose] = useState(false);
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState({});

  const getUserdetails = () => {
    SUserDetailsService.getUserDetails()
      .then((user: any) => {
        const admin = user.isSuperUser;
        if (!admin) {
          setErrorStatus('Unauthorized user');
          setError(true);
        }
      })
      .catch((err: Error) => {
        setErrorStatus(err);
        console.log('err', err);
        setError(true);
      });
  };

  useEffect(() => {
    getUserdetails();
  }, []);

  return (
    <div className="wrapper-container">
      {!error ? (
        <div
          className={`page-container ${!sidebarClose ? 'sidebar-open' : ''}`}
        >
          <Sidebar close={sidebarClose} setClose={setSidebarClose}></Sidebar>
          <div
            className={`right-column ${
              !sidebarClose ? 'sidebar-open-right-column' : ''
            }`}
          >
            <div className="header-container">
              <div className="header-component">
                <HeaderComponent />
              </div>
              <div className="profile-component">
                <ProfileContainerComponent />
              </div>
            </div>
            <Routes>
              <Route path="/" element={<Project />} />
              <Route path="/location" element={<Location />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/provider-setup" element={<TSOSetup />} />
              <Route path="/driverfunction" element={<DriverFunction />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/embeddedIFrame" element={<Map />} />
            </Routes>
          </div>
        </div>
      ) : (
        <FallBack errorStatus={errorStatus} />
      )}
    </div>
  );
};
