/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Silpa Sivadas, silpa.sivadas@h2-digital.com, January 2024
*******************************************************/
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import './TSOSetup.scss';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import STeamsService from 'services/teams/teams.service';
import SUserService from 'services/user/user.service';
import { isEmpty } from 'lodash';
import { TeamsSettingsFragment } from './TeamsSettingsSetup';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { TeamsFormData } from 'interfaces/teams.interface';

interface ITeamsSetupProps {
  projectId: number | undefined;
  locationId: number | undefined;
  userAADObjectId: string;
  setTeamsCreated: Dispatch<SetStateAction<boolean>>;
  formData: TeamsFormData;
  setFormData: Dispatch<SetStateAction<TeamsFormData>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userName?: any;
}

//used in TSO set up and also in Teams tab
export const TeamsFragment: FunctionComponent<ITeamsSetupProps> = (props) => {
  const {
    projectId,
    locationId,
    userAADObjectId,
    setTeamsCreated,
    formData,
    setFormData,
    userName,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [memberAdditionError, setMemberAdditionError] = useState<null | string>(
    null
  );
  const [isCreateTeamsButtonEnabled, setCreateTeamsButtonEnabled] =
    useState(false);
  useEffect(() => {
    if (projectId)
      SUserService.getUserDetails().then((userDetailsResponse: any) => {
        if (userDetailsResponse)
          setFormData((prevState) => ({
            ...prevState,
            ownerAADObjectId: userAADObjectId,
            projectId: projectId.toString(),
            memberAADObjectId: userAADObjectId,
          }));
      });
  }, []);

  const handleFormChange = (event: {
    target: { name: string; value: any };
  }) => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedFormData = {
      ...formData,
      teamsDetails: {
        ...formData.teamsDetails,
        [name]: value,
      },
    };
    setFormData(updatedFormData);
  };
  const createNewTeams = () => {
    setLoading(true);
    STeamsService.createNewTeams(formData)
      .then((res) => {
        setLoading(false);
        if (res.memberAdditionError) {
          setMemberAdditionError(res.memberAdditionError);
          showSnackbar(
            res.memberAdditionError +
              ' Please try adding the member to the teams later!',
            'error'
          );
        } else showSnackbar('Successfully Created Teams!', 'success');
        setTeamsCreated(true);
        setFormData((prevTeamsDetails) => ({
          ...prevTeamsDetails,
          teamsDetails: {
            ...prevTeamsDetails.teamsDetails,
            channelName: res.channelName,
            teamsId: res.teamsId,
            channelId: res.channelId,
            notificationLink: res.notificationLink,
          },
        }));
      })
      .catch(() => {
        setLoading(false);
        showSnackbar(
          "Unfortunately, Teams Couldn't be created. Please try again!",
          'error'
        );
      });
  };

  const checkIfFormIncomplete = () => {
    const isAnyFieldEmpty =
      isEmpty(formData.teamsDetails.teamsName.trim()) ||
      isEmpty(formData.teamsDetails.channelName.trim());
    return isAnyFieldEmpty;
  };

  useEffect(() => {
    setCreateTeamsButtonEnabled(checkIfFormIncomplete());
  }, [formData]);

  return formData.teamsDetails.teamsId && locationId ? (
    <TeamsSettingsFragment
      locationId={locationId}
      userName={userName}
      formData={formData}
      memberAdditionError={memberAdditionError}
    />
  ) : (
    <Grid container xs={12} className="project-container">
      <Grid xs={8} container className="project-details-container">
        <Grid xs={6} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            autoFocus
            margin="dense"
            id="teamsName"
            label="Teams Name"
            type="text"
            variant="outlined"
            value={formData.teamsDetails.teamsName || ''}
            name="teamsName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid xs={6} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            autoFocus
            margin="dense"
            id="channelName"
            label="Channel Name"
            type="text"
            variant="outlined"
            value={formData.teamsDetails.channelName || ''}
            name="channelName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid
          xs={12}
          className="teams-detail-input-container project-detail-input-container"
        >
          <TextField
            className="project-detail-input "
            autoFocus
            margin="dense"
            id="teamsDescription"
            label="Teams Description"
            type="text"
            variant="outlined"
            value={formData.teamsDetails.teamsDescription || ''}
            name="teamsDescription"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid
          xs={12}
          className="teams-detail-input-container project-detail-input-container"
        >
          <TextField
            className="project-detail-input"
            autoFocus
            margin="dense"
            id="channelDescription"
            label="Channel Description"
            type="text"
            variant="outlined"
            value={formData.teamsDetails.channelDescription || ''}
            name="channelDescription"
            onChange={handleFormChange}
          />
        </Grid>
      </Grid>
      <Grid xs={4} container className="project-details-container">
        <Grid xs={12}>
          <Button
            className="step-button"
            variant="contained"
            color="success"
            onClick={createNewTeams}
            sx={{ mt: 1, mr: 1 }}
            disabled={isLoading || isCreateTeamsButtonEnabled}
          >
            {isLoading ? <CircularProgress size={'20px'} /> : 'Create Teams'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
