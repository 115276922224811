export enum PowerBIReportTypes {
  SIZINGANDCOSTING = 'SIZINGANDCOSTING',
  SUMMARY = 'SUMMARY',
}

export enum ReportStatus {
  ACTIVE = 'ACTIVE',
  HIDE = 'HIDE',
}

export interface IReportDetails {
  powerBIReportTypes: PowerBIReportTypes;
  reportStatus: ReportStatus;
  reportId: string;
  datasetId: string;
  workspaceId: string;
  reportName: string;
  roleName: string;
}
