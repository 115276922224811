import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ImageIcon from '@mui/icons-material/Image';
import { ChangeEvent, useRef, useState } from 'react';
import SProjectService from '../../services/project/project.service';
import SUserService from '../../services/user/user.service';
import { ProjectDetails, ProjectDetailsError } from 'types/project';
import STeamsService from '../../services/teams/teams.service';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import { CircularProgress } from '@mui/material';
import { newProjectCreationMsgTeams } from '../../utils/teamsMessage';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import type { Address } from 'interfaces/location.interface';
import { initialProjectDetails } from 'constants/projectTable';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { CreateProjectValidator } from 'utils/CreateProjectValidator';
import { matches } from 'lodash';

export default function NewProjectDialog(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
}) {
  const { open, setOpen, setRefresh } = props;
  const [projectLogo, setprojectLogo] = useState<Blob>();
  const [userAvatar, setUserAvatar] = useState<Blob>();
  const projectLogoInput = useRef<HTMLInputElement>(null);
  const userImageInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newProjectDetails, setNewProjectDetails] = useState<ProjectDetails>(
    initialProjectDetails
  );
  const [newProjectDetailsError, setNewProjectDetailsError] =
    useState<ProjectDetailsError>();

  const { showSnackbar } = useSnackbar();

  const onFieldsChange = async (value: string | Address, key: string) => {
    if (key === 'address' && typeof value !== 'string') {
      setNewProjectDetails({
        ...newProjectDetails,
        address: value.freeformAddress,
        lat: value.lat,
        lng: value.lng,
      });
    } else {
      setNewProjectDetails({
        ...newProjectDetails,
        [key]: value,
      });
    }
    setNewProjectDetailsError({
      ...newProjectDetailsError,
      [key]: '',
    });
  };

  const handleClose = () => {
    setNewProjectDetails(initialProjectDetails);
    setNewProjectDetailsError(undefined);
    setUserAvatar(undefined);
    setprojectLogo(undefined);
    setOpen(false);
  };

  const createNewProject = async () => {
    let errors = CreateProjectValidator(
      newProjectDetails,
      userAvatar,
      projectLogo
    );
    if (newProjectDetails.email && !('email' in errors)) {
      const userExists = await SUserService.checkUserExists(
        newProjectDetails.email
      );
      if (userExists.data) {
        errors = { ...errors, email: 'Principal Name already Exists!' };
      }
    }
    if (matches(errors)({}) && projectLogo && userAvatar) {
      setIsLoading(true);
      try {
        const result = await SProjectService.createNewProject(
          newProjectDetails
        );
        await SProjectService.uploadLogo(projectLogo, result.projectId);
        await SUserService.uploadAvatar(userAvatar, result.user.userId);
        setIsLoading(false);
        if (result.projectId) {
          if (result.user) {
            const message = newProjectCreationMsgTeams(result);
            STeamsService.sendNotificationInTeams(
              h2DigitalTeamsForNotification.link,
              message
            );
          }
          showSnackbar('New Project is created successfully!', 'success');
        }
      } catch {
        setIsLoading(false);
        showSnackbar('New Project creation Failed!', 'error');
      }
      handleClose();
      setRefresh(true);
    } else {
      setNewProjectDetailsError((prev) => {
        return { ...prev, ...errors };
      });
    }
  };

  const handleprojectLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setNewProjectDetailsError({
      ...newProjectDetailsError,
      projectLogo: '',
    });
    setprojectLogo(file);
  };

  const handleProjectLogoClick = () => {
    projectLogoInput?.current?.click();
  };

  const handleUserAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setNewProjectDetailsError({
      ...newProjectDetailsError,
      userAvatar: '',
    });
    setUserAvatar(file);
  };

  const handleUserImageClick = () => {
    userImageInput?.current?.click();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <div className="new-project-form-container">
          <div className="new-project-image-container">
            <div className="new-project-image-title">Project Logo *</div>
            {!projectLogo && (
              <div className="new-project-image-wrap">
                <ImageIcon
                  className="new-project-image-icon"
                  onClick={handleProjectLogoClick}
                />
              </div>
            )}
            {projectLogo && (
              <div className="new-project-image-wrap">
                <img
                  className="new-project-image"
                  src={URL.createObjectURL(projectLogo)}
                  onClick={handleProjectLogoClick}
                />
              </div>
            )}
            <div className="error-class">
              {newProjectDetailsError?.projectLogo
                ? 'Project Image cannot be empty'
                : ''}
            </div>
          </div>

          <input
            type="file"
            ref={projectLogoInput}
            onChange={handleprojectLogoChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />

          <div className="new-project-name-container">
            <TextField
              required
              autoFocus
              margin="dense"
              id="pName"
              label="Project Name"
              type="text"
              variant="standard"
              value={newProjectDetails?.name || ''}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'name');
              }}
              helperText={newProjectDetailsError?.name}
              error={Boolean(newProjectDetailsError?.name)}
            />
          </div>

          <div className="new-project-address-container">
            <AddressAutocomplete
              defaultValue={newProjectDetails?.address || ''}
              isRequired={true}
              helperText={newProjectDetailsError?.address}
              handleFormChange={(e: Address) => onFieldsChange(e, 'address')}
            />
          </div>

          <div className="new-project-description-container">
            <TextField
              required
              fullWidth
              multiline
              rows={2}
              autoFocus
              margin="dense"
              id="description"
              label="Description"
              type="text"
              variant="standard"
              value={newProjectDetails?.description || ''}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'description');
              }}
              helperText={newProjectDetailsError?.description}
              error={Boolean(newProjectDetailsError?.description)}
            />
          </div>
        </div>
        <div className="new-project-form-container">
          <div className="new-project-image-container">
            <div className="new-project-image-title">User Image *</div>
            {!userAvatar && (
              <div className="new-project-image-wrap">
                <ImageIcon
                  className="new-project-image-icon"
                  onClick={handleUserImageClick}
                />
              </div>
            )}
            {userAvatar && (
              <div className="new-project-image-wrap">
                <img
                  className="new-project-image"
                  src={URL.createObjectURL(userAvatar)}
                  onClick={handleUserImageClick}
                />
              </div>
            )}
            <div className="error-class">
              {newProjectDetailsError?.userAvatar
                ? 'User Logo cannot be empty'
                : ''}
            </div>
          </div>
          <input
            type="file"
            ref={userImageInput}
            onChange={handleUserAvatarChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />

          <div className="new-project-name-container">
            <TextField
              required
              autoFocus
              margin="dense"
              id="uFName"
              label="First Name"
              type="text"
              variant="standard"
              value={newProjectDetails?.firstName}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'firstName');
              }}
              helperText={newProjectDetailsError?.firstName}
              error={Boolean(newProjectDetailsError?.firstName)}
            />
          </div>

          <div className="new-project-name-container">
            <TextField
              required
              autoFocus
              margin="dense"
              id="uLName"
              label="Last Name"
              type="text"
              variant="standard"
              value={newProjectDetails?.lastName || ''}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'lastName');
              }}
              helperText={newProjectDetailsError?.lastName}
              error={Boolean(newProjectDetailsError?.lastName)}
            />
          </div>

          <div className="new-project-name-container">
            <TextField
              required
              fullWidth
              autoFocus
              margin="dense"
              id="principalName"
              label="User Principal Name"
              type="text"
              variant="standard"
              value={newProjectDetails?.email || ''}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'email');
              }}
              helperText={newProjectDetailsError?.email}
              error={Boolean(newProjectDetailsError?.email)}
            />
          </div>
          <div className="new-project-name-container">
            <TextField
              required
              fullWidth
              autoFocus
              margin="dense"
              id="secondaryEmail"
              label="User Secondary Email"
              type="text"
              variant="standard"
              value={newProjectDetails?.secondaryEmail || ''}
              onChange={(e) => {
                onFieldsChange(e.target.value, 'secondaryEmail');
              }}
              helperText={newProjectDetailsError?.secondaryEmail}
              error={Boolean(newProjectDetailsError?.secondaryEmail)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={createNewProject} disabled={isLoading}>
          {isLoading ? <CircularProgress size={'20px'} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
