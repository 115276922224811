import { Tag } from './tag';

export interface ILocationDetails {
  id: number;
  projectId: number;
  name: string;
  address: string;
  lat: number;
  lng: number;
  description: string;
  goLiveDate: string;
  image: string;
  thumbnail?: string;
  logo: string;
  electrolyserSize: number;
  MSTeamsID: string;
  MSTeamsName: string;
  MSTeamsChannel: string;
  NotificationLink: string;
  timezone: string;
  District: string;
  state: string;
  country: string;
  H2DigitalType: number;
  active: boolean;
  connectorUrl: string;
  firstName: string;
  lastName: string;
  company: string;
  contactPersonEmail: string;
  H2DigitalLicense?: H2DigitalLicense;
  updatedAt?: string | null;
  createdAt?: string | null;
  deletedAt?: string | null;
  linkedInUrl: string;
  website: string;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  tags: Tag[];
}

export enum H2DigitalLicense {
  FREE = 10,
  PROFESSIONAL = 20,
  PREMIUM = 30,
}

export interface IEncodeDetails extends Partial<ILocationDetails> {
  logo?: string | undefined;
}
export interface LocationData {
  name?: string;
  address?: string;
  lat?: number;
  lng?: number;
}

export interface LocationDataError {
  name?: string;
  address?: string;
}

export enum LocationTypes {
  Provider = 10,
}

export interface ILocationTypeMap {
  label: string;
  value: number;
}

export const locationTypeMap: ILocationTypeMap[] = [
  { value: LocationTypes.Provider, label: 'Provider' },
];
