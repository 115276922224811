import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import './TSOSetup.scss';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ImageIcon from '@mui/icons-material/Image';
import AddressAutocomplete from 'components/AddressAutocomplete/AddressAutocomplete';
import { Address } from 'interfaces/location.interface';
import { UserTypes } from 'constants/user';
import { ILocationDetails, LocationTypes } from 'types/location';
import SLocationService from 'services/location/location.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';

interface IProjectSetupProps {
  providerLocationDetails: Partial<ILocationDetails>;
  setProviderLocationDetails: Dispatch<
    SetStateAction<Partial<ILocationDetails>>
  >;
  projectLogoFile: Blob;
  setProjectLogoFile: Dispatch<SetStateAction<Blob>>;
  projectImageFile: Blob;
  setProjectImageFile: Dispatch<SetStateAction<Blob>>;
  setCreateProjectEnabled: Dispatch<SetStateAction<boolean>>;
}
export const ProjectFragment: FunctionComponent<IProjectSetupProps> = (
  props
) => {
  const {
    providerLocationDetails,
    setProviderLocationDetails,
    projectLogoFile,
    setProjectLogoFile,
    projectImageFile,
    setProjectImageFile,
    setCreateProjectEnabled,
  } = props;
  const projectLogoInput = useRef<HTMLInputElement>(null);
  const [projectLogoUrl, setProjectLogoUrl] = useState<string>('');
  const emptyBlob = new Blob([], { type: 'application/octet-stream' });

  const projectImageInput = useRef<HTMLInputElement>(null);
  const [projectImageUrl, setProjectImageUrl] = useState<string>('');
  const [allProviderNames, setAllProviderNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    // Fetch all provider location names
    SLocationService.getAllLocationsNames([LocationTypes.Provider])
      .then((responses) => {
        setAllProviderNames(
          responses.map((response: { name: string }) => response.name)
        );
      })
      .catch(() => {
        showSnackbar('Failed to fetch location names!', 'error');
      });
  }, []);
  const handleProjectLogoClick = () => {
    projectLogoInput?.current?.click();
  };
  const handleprojectLogoChange = (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      setProjectLogoFile(emptyBlob);
      return;
    }
    setProjectLogoFile(event.target.files[0]);
  };
  const handleLogoUrlChange = (event: { target: { value: string } }) => {
    setProjectLogoUrl(event.target.value);
  };
  const handleUploadLogo = async () => {
    if (projectLogoUrl.trim() !== '') {
      try {
        fetch(projectLogoUrl)
          .then((response) => response.blob())
          .then((file) => {
            if (file.type.startsWith('image/')) {
              setProjectLogoFile(file);

              setProjectLogoUrl('');
            } else {
              console.log('Fetch Logo Failed!', file);
              setProjectLogoUrl('');
            }
          })
          .catch((error) => {
            console.error('Logo fetch failed', error);
            setProjectLogoUrl('');
          });
      } catch (error) {
        console.error('Logo fetch failed', error);
        setProjectLogoUrl('');
      }
    } else {
      console.error('please enter the URL', 'info');
    }
  };

  const handleProjectImageClick = () => {
    projectImageInput?.current?.click();
  };
  const handleprojectImageChange = (event: any) => {
    if (!event.target.files || event.target.files.length === 0) {
      setProjectImageFile(emptyBlob);
      return;
    }
    setProjectImageFile(event.target.files[0]);
  };
  const handleImageUrlChange = (event: { target: { value: string } }) => {
    setProjectImageUrl(event.target.value);
  };
  const handleUploadImage = async () => {
    if (projectImageUrl.trim() !== '') {
      try {
        fetch(projectImageUrl)
          .then((response) => response.blob())
          .then((file) => {
            if (file.type.startsWith('image/')) {
              setProjectImageFile(file);

              setProjectImageUrl('');
            } else {
              console.log('Fetch Image Failed!');
              setProjectImageUrl('');
            }
          })
          .catch((error) => {
            console.error('Image fetch failed', error);
            setProjectImageUrl('');
          });
      } catch (error) {
        console.error('Image fetch failed', error);
        setProjectImageUrl('');
      }
    } else {
      console.error('please enter the URL', 'info');
    }
  };
  const checkIfFormIncomplete = () => {
    const isAnyFieldEmpty =
      Object.values(providerLocationDetails).some((value) => !value) ||
      projectLogoFile.size === 0 ||
      projectImageFile.size === 0 ||
      errorMessage.length > 0;
    return isAnyFieldEmpty;
  };

  const onFieldsChange = async (
    value: string | Address | number,
    key: string
  ) => {
    if (key === 'name' && typeof value === 'string') {
      if (
        allProviderNames.length &&
        allProviderNames.some(
          (item) => item.toLowerCase() === value.toLowerCase()
        )
      ) {
        setErrorMessage(`Provider ${value} already exists`);
      } else {
        setErrorMessage('');
      }
      setProviderLocationDetails({ ...providerLocationDetails, name: value });
    } else if (
      key === 'address' &&
      typeof value !== 'string' &&
      typeof value !== 'number'
    ) {
      setProviderLocationDetails({
        ...providerLocationDetails,
        address: value.freeformAddress,
        lat: value.lat,
        lng: value.lng,
      });
    } else {
      setProviderLocationDetails({ ...providerLocationDetails, [key]: value });
    }
  };

  useEffect(() => {
    setCreateProjectEnabled(checkIfFormIncomplete());
  }, [providerLocationDetails, projectImageFile, projectLogoFile]);

  return (
    <Grid container spacing={2} xs={12} className="project-container">
      <Grid xs={2} className="project-image-container">
        <Grid xs={12} className="project-image">
          <div className="project-image-title">Project Logo*</div>
          {projectLogoFile.size === 0 && (
            <div className="project-image-wrap">
              <ImageIcon
                className="project-image-icon"
                onClick={handleProjectLogoClick}
              />
            </div>
          )}
          {projectLogoFile.size !== 0 && (
            <div className="project-image-wrap">
              <img
                className="project-img"
                src={URL.createObjectURL(projectLogoFile)}
                onClick={handleProjectLogoClick}
              />
            </div>
          )}
          <input
            type="file"
            id="image-text-input"
            ref={projectLogoInput}
            onChange={handleprojectLogoChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <Tooltip title="Enter the image URL to fetch the image">
            <TextField
              id="image-text-input"
              type="text"
              size="small"
              className="project-image-input"
              onChange={handleLogoUrlChange}
              onBlur={handleUploadLogo}
              value={projectLogoUrl}
              placeholder="Enter image URL"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid xs={2} className="project-image-container">
        <Grid xs={12} className="project-image">
          <div className="project-image-title">Project Image</div>
          {projectImageFile.size === 0 && (
            <div className="project-image-wrap">
              <ImageIcon
                className="project-image-icon"
                onClick={handleProjectImageClick}
              />
            </div>
          )}
          {projectImageFile.size !== 0 && (
            <div className="project-image-wrap">
              <img
                className="project-img"
                src={URL.createObjectURL(projectImageFile)}
                onClick={handleProjectImageClick}
              />
            </div>
          )}
          <input
            type="file"
            id="image-text-input"
            ref={projectImageInput}
            onChange={handleprojectImageChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <Tooltip title="Enter the image URL to fetch the image">
            <TextField
              size="small"
              id="image-text-input"
              type="text"
              className="project-image-input"
              onChange={handleImageUrlChange}
              onBlur={handleUploadImage}
              value={projectImageUrl}
              placeholder="Enter image URL"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid xs={8} container className="project-details-container">
        <Grid xs={6} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            autoFocus
            margin="dense"
            id="pName"
            label="Project Name"
            type="text"
            variant="outlined"
            value={providerLocationDetails?.name}
            onChange={(e) => {
              onFieldsChange(e.target.value, 'name');
            }}
          />{' '}
          {errorMessage && (
            <div className="error-text-provider">{errorMessage}</div>
          )}
        </Grid>
        <Grid xs={6} className="project-detail-input-container">
          <AddressAutocomplete
            defaultValue={providerLocationDetails?.address ?? ''}
            helperText={''}
            isRequired={true}
            handleFormChange={(e) => {
              onFieldsChange(e, 'address');
            }}
            variant="outlined"
          ></AddressAutocomplete>
        </Grid>
        <Grid xs={12} className="project-detail-input-container">
          <FormControl fullWidth>
            <InputLabel id="h2DigitalType-label" required>
              Type
            </InputLabel>
            <Select
              labelId="h2DigitalType-label"
              id="H2DigitalType"
              name="H2DigitalType"
              value={providerLocationDetails?.H2DigitalType}
              label="Type"
              onChange={(e) => {
                onFieldsChange(e.target.value, 'H2DigitalType');
              }}
            >
              <MenuItem value={UserTypes.Provider}>Provider</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* The below code is commented for future use.... */}
        {/* <Grid xs={5} className="project-detail-input-container">
          <FormControl fullWidth>
            <InputLabel id="cOGEDigitization-label">
              cOGE Digitization*
            </InputLabel>
            <Select
              labelId="cOGEDigitization-label"
              id="cOGEDigitization"
              name="cOGEDigitization"
              value={newProjectDetails?.cOGEDigitization || ''}
              label="cOGE Digitization"
              onChange={handleFormChange}
            >
              <MenuItem value={'A-high'}>A-high</MenuItem>
              <MenuItem value={'B-medium'}>B-medium</MenuItem>
              <MenuItem value={'C-low'}>C-low</MenuItem>
              <MenuItem value={'D-no'}>D-no</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid xs={12} className="project-detail-input-container pdescription">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="description"
            label="Description"
            type="text"
            variant="outlined"
            value={providerLocationDetails?.description}
            name="description"
            onChange={(e) => {
              onFieldsChange(e.target.value, 'description');
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
