import { ProjectDetails } from 'types/project';
import HttpClient from '../httpClient/httpClient';
import {
  INewUserDetails,
  IUserDetailsWithTeams,
} from 'pages/Users/components/NewUserWithTeams';
import { commonConstants } from 'constants/common';
import { IProjectUpdateDto } from 'interfaces/project.interface';
import { IProjectDetails } from 'interfaces/project.interface';
import { ILocationDetails } from 'types/location';

class ProjectService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async createNewProject(newProjectDetails: ProjectDetails) {
    return this.httpClient
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project-onboarding`,
        newProjectDetails
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async createNewProvider(locationDetails: Partial<ILocationDetails>) {
    return this.httpClient
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project-onboarding/provider`,
        locationDetails
      )
      .then((response) => {
        const result = response.data;
        return result;
      });
  }

  async uploadLogo(file: string | Blob, projectID: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/logo/${projectID}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.logo;
  }

  async uploadImage(file: string | Blob, projectID: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/project-image/${projectID}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.projectImage;
  }

  async findNames() {
    return this.httpClient
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/all/names`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getAllProjects() {
    return this.httpClient
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/all/details`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getProjectAnalytics() {
    return this.httpClient
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/all/analytics`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async updateProjectDetails(
    projectData: IProjectUpdateDto,
    projectId: string
  ) {
    const response = await this.httpClient.patch(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/update-project/${projectId}`,
      projectData
    );
    return response;
  }

  getProjectDetails(projectId: string): Promise<IProjectDetails> {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/findByAdmin/${projectId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async deleteProject(projectId: string) {
    return this.httpClient
      .delete(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/delete/${projectId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async addNewUserToProject(projectId: number, user: INewUserDetails) {
    const result = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/add-user/${projectId}`,
      user
    );
    return result;
  }

  //create user and add to teams
  async addNewUserToProjectAndTeams(
    projectId: number,
    user: IUserDetailsWithTeams
  ) {
    const result = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/add-user-team/${projectId}`,
      user
    );
    return result;
  }
}
const SProjectService = new ProjectService();
export default SProjectService;
